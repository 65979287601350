/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import { lazy } from "react";
import { OrderedSet } from "immutable";
import { AvailableRoles } from "./ReduxState";
import { Label, LeasingCompany } from "./Users/Types";

// const Home = lazy(() => import("../App/components/09-views/02-pages/Home"));
// const Reports = lazy(() => import("../App/components/09-views/02-pages/Reports"));
const ManageRoles = lazy(() => import("../App/components/09-views/02-pages/ManageRoles"));
const ManualReports = lazy(() => import("../App/components/09-views/02-pages/ManualReports"));
const OrganizationalUnits = lazy(() => import("../App/components/09-views/02-pages/OrganizationalUnits"));
const AssignRoles = lazy(() => import("../App/components/09-views/02-pages/AssignRoles"));
const AddRole = lazy(() => import("../App/components/09-views/00-blocks/Roles/Addrole"));
const UpdateRole = lazy(() => import("../App/components/09-views/00-blocks/Roles/UpdateRole"));
const ServiceForms = lazy(() => import("../App/components/09-views/02-pages/ServiceForms"));
const ManageKPIS = lazy(() => import("../App/components/09-views/02-pages/ManageKPIs"));
const ManageKPISPerCustomer = lazy(() => import("../App/components/09-views/02-pages/ManageKPIsPerCustomer"));

/**
 *
 */
export type DisplayOf<T extends string | number> = Readonly<Record<T, string>>;

/**
 *
 */
export const LeaseCompanyDisplays: DisplayOf<LeasingCompany> = {
    [LeasingCompany.VolkswagenPonFinancialServices]: "Volkswagen Pon Financial Services",
    [LeasingCompany.CupraFinancialServices]: "Cupra Financial Services",
    [LeasingCompany.MANFinancialServices]: "MAN Financial Services",
    [LeasingCompany.AudiFinancialServices]: "Audi Financial Services",
    [LeasingCompany.DutchLease]: "DutchLease",
    [LeasingCompany.XLLease]: "XLLease",
    [LeasingCompany.PorscheFinancialServices]: "Porsche Financial Services",
    [LeasingCompany.SEATFinancialServices]: "SEAT Financial Services",
    [LeasingCompany.SkodaFinancialServices]: "Škoda Financial Services",
    [LeasingCompany.VolkswagenFinancialServices]: "VWFS",
};

export const LabelDisplays: DisplayOf<Label> = {
    [Label._700]: "A-Point Lease",
    [Label.MUN]: "Muntstad Lease",
    [Label.VAL]: "Vallei Auto lease",
    [Label.MAA]: "Maas-De Koning Lease",
    [Label.HKA]: "HK Auto lease",
    [Label.TBL]: "TB Lease",
    [Label.CUP]: "CUPRA Financial Services",
    [Label._712]: "ArenaLease",
    [Label.MAN]: "MAN Financial Services",
    [Label.AUL]: "Audi Financial Services",
    [Label._730]: "Auto Hoogenboom",
    [Label._725]: "Century Lease",
    [Label.DLN]: "DutchLease",
    [Label.LUN]: "XLLease",
    [Label._790]: "Pon Dealer Lease",
    [Label.POR ]: "Porsche Financial Services",
    [Label.SEL]: "SEAT Financial Services",
    [Label.SKL]: "ŠKODA Financial Services",
    [Label._710]: "Van den Udenhout Lease",
    [Label.VWL]: "Volkswagen Financial Services Fleet",
    [Label.CAR]: "Volkswagen Financial Services Bedrijfswagens",
    [Label._711]: "Wealer",
    [Label._740]: "Wittebrug Lease",
    [Label.ALM]: "ALMN",
    [Label.ZUI]: "Zuidlease",
};

/**
 *
 */
export enum Pages {
    HOME = "HOME",
    REPORTS = "REPORTS",
    MANAGE_ROLES = "MANAGE_ROLES",
    ASSIGN_ROLE = "ASSIGN_ROLE",
    ORGANIZATIONAL_UNITS = "ORGANIZATIONAL_UNITS",
    MANUAL_REPORTS = "MANUAL_REPORTS",
    SERVICE_FORMS = "SERVICE_FORMS",
    MANAGE_KPIS = "MANAGE_KPIS",
    MANAGE_CUSTOMER_KPIS = "MANAGE_CUSTOMER_KPIS",
}

/**
 *
 */
export enum SubPages {
    ADD_ROLE = "ADD_ROLE",
    UPDATE_ROLE = "UPDATE_ROLE",
}

/**
 *
 */
export enum PageTabs {
}

export interface Tab {
    title: string;
    description: string;
    roles: string[];
    component?: React.FunctionComponent | React.ComponentState;
}

export interface Page {
    title: string;
    description: string;
    path: string;
    roles: string[];
    component?: React.FunctionComponent | React.ComponentState;
    beta?: boolean;
    pagination?: boolean;
    parent?: Page;
    asMenu?: boolean;
    subpages: OrderedSet<SubPages>;
    tabs?: OrderedSet<PageTabs>;
}

/**
 *
 */
export const PageDisplay: Readonly<Record<Pages, Page>> = {
    [Pages.HOME]: {
        title: "app.dashboard",
        description: "Welcome to home",
        component: undefined,
        path: "/",
        roles: [AvailableRoles.ACCOUNTMANAGER, AvailableRoles.FLEETMANAGER, AvailableRoles.SUPERADMIN],
        subpages: OrderedSet([]),
        tabs: OrderedSet([]),
    },
    [Pages.REPORTS]: {
        title: "app.reports",
        description: "Welcome to reports",
        component: undefined,
        path: "/reports",
        roles: [AvailableRoles.ACCOUNTMANAGER, AvailableRoles.FLEETMANAGER, AvailableRoles.SUPERADMIN],
        subpages: OrderedSet([]),
        tabs: OrderedSet([]),
    },
    [Pages.ASSIGN_ROLE]: {
        title: "app.assign-roles",
        description: "Welcome to assign roles",
        component: AssignRoles,
        path: "/assign",
        roles: [AvailableRoles.FLEETMANAGER, AvailableRoles.SUPERADMIN],
        subpages: OrderedSet([]),
        tabs: OrderedSet([]),
    },
    [Pages.MANAGE_ROLES]: {
        title: "app.manage-roles",
        description: "Welcome to manage roles",
        component: ManageRoles,
        path: "/manage",
        roles: [AvailableRoles.ACCOUNTMANAGER, AvailableRoles.FLEETMANAGER, AvailableRoles.SUPERADMIN],
        subpages: OrderedSet([SubPages.ADD_ROLE, SubPages.UPDATE_ROLE]),
        tabs: OrderedSet([]),
    },
    [Pages.MANAGE_KPIS]: {
        title: "app.manage-kpis",
        description: "Manage KPIs",
        component: ManageKPIS,
        path: "/manage-kpis",
        roles: [AvailableRoles.SUPERADMIN],
        subpages:  OrderedSet([]),
        tabs: OrderedSet([]),
    },
    [Pages.MANAGE_CUSTOMER_KPIS]: {
        title: "app.manage-kpis-per-customer",
        description: "Manage KPIs per customer",
        component: ManageKPISPerCustomer,
        path: "/manage-kpis-per-customer",
        roles: [AvailableRoles.FLEETMANAGER],
        subpages:  OrderedSet([]),
        tabs: OrderedSet([]),
    },
    [Pages.MANUAL_REPORTS]: {
        title: "app.manual-reporting",
        description: "Manual reporting",
        component: ManualReports,
        beta: true,
        path: "/manual-reports",
        roles: [AvailableRoles.ACCOUNTMANAGER, AvailableRoles.FLEETMANAGER, AvailableRoles.SUPERADMIN],
        subpages: OrderedSet([]),
        tabs: OrderedSet([]),
    },
    [Pages.ORGANIZATIONAL_UNITS]: {
        title: "app.organizational-units",
        description: "Manage organization units",
        component: OrganizationalUnits,
        beta: true,
        path: "/organizational-units",
        roles: [AvailableRoles.ACCOUNTMANAGER, AvailableRoles.FLEETMANAGER, AvailableRoles.SUPERADMIN],
        subpages:  OrderedSet([]),
        tabs: OrderedSet([]),
    },
    [Pages.SERVICE_FORMS]: {
        title: "app.service-forms",
        description: "Send a mail",
        component: ServiceForms,
        beta: true,
        path: "/service-forms",
        roles: [AvailableRoles.ACCOUNTMANAGER, AvailableRoles.FLEETMANAGER, AvailableRoles.SUPERADMIN],
        subpages:  OrderedSet([]),
        tabs: OrderedSet([]),
    },
};

/**
 *
 */
export const SubPageDisplay: Readonly<Record<SubPages, Page>> = {
    [SubPages.ADD_ROLE]: {
        title: "app.add-role",
        description: "You can add a new role here",
        component: AddRole,
        path: "/manage/add",
        roles: [AvailableRoles.ACCOUNTMANAGER, AvailableRoles.FLEETMANAGER, AvailableRoles.SUPERADMIN],
        subpages: OrderedSet([]),
        tabs: OrderedSet([]),
    },
    [SubPages.UPDATE_ROLE]: {
        title: "app.update-role",
        description: "You can update a role here",
        component: UpdateRole,
        path: "/manage/:roleId/update",
        roles: [AvailableRoles.ACCOUNTMANAGER, AvailableRoles.FLEETMANAGER, AvailableRoles.SUPERADMIN],
        subpages: OrderedSet([]),
        tabs: OrderedSet([]),
    },
};

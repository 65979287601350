/* eslint-disable no-underscore-dangle */
/* eslint-disable react/display-name */
/* eslint-disable max-len */
import * as React from "react";
import { Map, OrderedSet } from "immutable";
import { connect } from "react-redux";
import { BaseConsumer } from "../../../components/core/03-base/Base";
import { FlexColumn } from "../../../components/core/03-base/Flex/Column";
import { FlexRow } from "../../../components/core/03-base/Flex/Row";
import { FlexSidebar } from "../../../components/core/03-base/Flex/Sidebar";
import { FlexWrapperFocus } from "../../../components/core/03-base/FlexWrapper";
import { Logo } from "../../../components/core/03-base/Logo";
import { Button } from "../../../components/core/05-atoms/Button";
import { IconNames } from "../../../components/core/05-atoms/Icon";
import { Modal } from "../../../components/core/06-molecules/Modal";
import { UserInfo as UserInfoComp } from "../../../components/core/06-molecules/UserInfo";
import { NavbarIcon } from "../../../components/core/07-organisms/Navbar/Icon";
import { NavbarStackContent, NavbarStackRight } from "../../../components/core/07-organisms/Navbar/Stack";
import { DispatchFunc } from "../../../store/ActionTypes";
import { toggleSidebar } from "../../../store/AppActions";
import { Pages } from "../../../store/AppDisplays";
import { isRemoteLoading, ReduxState, RemoteErrors } from "../../../store/ReduxState";
import { ThemePalette, ThemeShadowSizes, ThemeSize, ThemeVariants, Themes } from "../../../theme/_Types";
import { BreakpointConsumer } from "../../../utils/Breakpoints";
import { flexSetFocus, modalShow } from "../../../utils/redux/ActionTypes";
import { SwipeBlock } from "../../../utils/swipe";
import * as I18n from "../../../utils/translations/I18n";
import backgroundImageAudi from "../../assets/images/sidebar-bg/audi.png";
import backgroundImageCupra from "../../assets/images/sidebar-bg/cupra.png";
import backgroundImageDealerLease from "../../assets/images/sidebar-bg/dealerlease.png";
import backgroundImageDutchLease from "../../assets/images/sidebar-bg/dutchlease.png";
import backgroundImageMAN from "../../assets/images/sidebar-bg/man.png";
import backgroundImagePorsche from "../../assets/images/sidebar-bg/porsche.png";
import backgroundImageSeat from "../../assets/images/sidebar-bg/seat.png";
import backgroundImageSkoda from "../../assets/images/sidebar-bg/skoda.png";
import backgroundImageVW from "../../assets/images/sidebar-bg/vw.png";
import backgroundImageVWB from "../../assets/images/sidebar-bg/vwb.png";
import backgroundImageXLLease from "../../assets/images/sidebar-bg/xllease.png";
import backgroundImagePolitie from "../../assets/images/sidebar-bg/politie.jpg";
import { DashboardMenu } from "./00-blocks/DashboardMenu";
import { RemoteScope } from "../../../store/RemoteTypes";
import { InitApp, MenuItems, MRole } from "../../../store/Init/Types";
import { SelectContextModal } from "./02-pages/SelectContextModal";
import { ReleaseVersion } from "../../../components/core/05-atoms/ReleaseVersion";
import { Label } from "../../../store/Users/Types";
import { LogoutBtn } from "../05-atoms/keycloaklogoutbtn";

import logoImageApoint from "../../assets/images/logos/apoint/logo-contrast.svg";
import logoImageAudi from "../../assets/images/logos/audi-fs/logo-contrast.svg";
import logoImageCenturyLease from "../../assets/images/logos/centurylease/logo-contrast.svg";
import logoImageCupra from "../../assets/images/logos/cupra-fs/logo-contrast.svg";
import logoImageDutchLease from "../../assets/images/logos/dutchlease/logo-contrast.svg";
import logoImageHKAutolease from "../../assets/images/logos/hkautolease/logo-contrast.svg";
import logoImageMaas from "../../assets/images/logos/maas/logo-contrast.svg";
import logoImageMan from "../../assets/images/logos/man/logo-contrast.svg";
import logoImagePondealer from "../../assets/images/logos/pondealer/logo-contrast.svg";
import logoImagePorsche from "../../assets/images/logos/porsche/logo-contrast.svg";
import logoImageSeat from "../../assets/images/logos/seat-fs/logo-contrast.svg";
import logoImageSkoda from "../../assets/images/logos/skoda-fs/logo-contrast.svg";
import logoImageTBLease from "../../assets/images/logos/tblease/logo-contrast.svg";
import logoImageUdenhout from "../../assets/images/logos/udenhout/logo-contrast.svg";
import logoImageVW from "../../assets/images/logos/vw-fs/logo-contrast.svg";
import logoImageVWPFS from "../../assets/images/logos/vwpfs/logo-contrast.svg";
import logoImageWealer from "../../assets/images/logos/wealer/logo-contrast.svg";
import logoImageWittebrug from "../../assets/images/logos/wittebrug/logo-contrast.svg";
import logoImageXLLease from "../../assets/images/logos/xllease/logo-contrast.svg";
import logoImageMuntstad from "../../assets/images/logos/muntstad/logo-contrast.svg";
import logoImageValleiLease from "../../assets/images/logos/vallei-autolease/logo-contrast.svg";
import logoImageAutoHoogenboom from "../../assets/images/logos/autohoogeboom/logo-contrast.svg";
import logoImageZuidLease from "../../assets/images/logos/zuidlease/logo-contrast.svg";
import logoPolitie from "../../assets/images/logos/politie/logo-contrast.svg";
import logoImageAlmn from "../../assets/images/logos/almn/logo-contrast.svg";
import { GlobalLoadingProps, getCurrentRole, isLoadingGlobal, loadingMessagesGlobal } from "../../../store/AppTypes";
import { KeycloakLoggedIn, KeycloakNotLoggedIn } from "../05-atoms/keycloakLoggedIn";
import { LoadingIndications, LoadingIndicator } from "../../../components/core/05-atoms/LoadingIndicator";
import { getText } from "../../../utils/translations/I18n";
import { Div } from "../../../components/core/03-base/Div";
import { Customer } from "../../../store/Customers/Types";

/**
 *
 */
interface StateProps extends GlobalLoadingProps {
    sidebarCollapsed: boolean;
    init?: InitApp;
    currentMenu?: MenuItems[];
    availablePages: OrderedSet<Pages>;
    hasMultipleRoles: () => boolean | undefined;
    hasCustomers: () => boolean | undefined;
    getCurrentRole?: MRole;
    getCurrentCustomer?: Customer;
    remoteErrors: Map<RemoteScope, RemoteErrors>;
}

/**
 *
 */
interface DispatchProps {
    toggleSidebar: (force?: boolean) => void;
    setFocus: () => void;
    triggerRoleModal: () => void;
}

type Props = StateProps & DispatchProps;

export const TriggerRoleModal = () => (
    () => (
        <Modal
            modalID={"role-modal"}
            theme={{ size: ThemeSize.SMALL }}
        >
            <SelectContextModal modalID={"role-modal"}/>
        </Modal>
    )
);

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    sidebarCollapsed: s.prop("sidebarCollapsed"),
    init: s.prop("remote").prop(RemoteScope.INIT),
    isLoading: {
        customers: isRemoteLoading(s, RemoteScope.CUSTOMERS),
        customer: !!s.prop("userContextCustomerIsLoading"),
        role: !!s.prop("userContextRoleIsLoading"),
        init: isRemoteLoading(s, RemoteScope.INIT),
    },
    isLoaded: {
        init: !!s.prop("remote").prop(RemoteScope.INIT),
    },
    getCurrentRole: getCurrentRole(s),
    getCurrentCustomer: s.prop("userContext")?.customer,
    availablePages: s.prop("pages").prop("pages"),
    hasMultipleRoles: () => {
        const roles = s.prop("remote")?.prop(RemoteScope.INIT)?.roles;
        return roles && roles?.length > 1;
    },
    hasCustomers: () => {
        const customers = s.prop("remote")?.prop(RemoteScope.CUSTOMERS);
        return customers && customers?.length > 0;
    },
    remoteErrors: s.prop("remoteErrors"),
});


/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    toggleSidebar: (force?: boolean) => dispatch(toggleSidebar(force)),
    setFocus: () => dispatch(flexSetFocus(FlexWrapperFocus.SIDEBAR_INACTIVE)),
    triggerRoleModal: () => dispatch(
        modalShow(
            "role-modal",
            TriggerRoleModal(),
        ),
    ),
});

/**
 *
 * @param _props
 */
class SideBarComp extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);
        this.updateCollapseState = this.updateCollapseState.bind(this);
        this.setFocus = this.setFocus.bind(this);
        this.updateCollapseStateWithIcon = this.updateCollapseStateWithIcon.bind(this);
        this.getSidebarImage = this.getSidebarImage.bind(this);
        this.getSidebarLogo = this.getSidebarLogo.bind(this);
    }

    public render() {
        return (
            <BaseConsumer>{({ getTheme }) =>
                (
                    <SwipeBlock detectSwipeDir={(value: string) => this.updateCollapseState(value === "Left")}>
                        <FlexSidebar isCollapsed={this.props.sidebarCollapsed}>
                            <BreakpointConsumer>
                                {(value) => (
                                    <>
                                        <FlexRow theme={{ palette: getTheme() === Themes.DUTCHLEASE ? ThemePalette.CONTRAST_PRIMARY : ThemePalette.BRAND_PRIMARY }}>
                                            <FlexColumn hasScrollable className="v-sidebar"
                                                style={{ backgroundImage: this.props.getCurrentRole?.label ? `url(${this.getSidebarImage(this.props.getCurrentRole?.label)})` : getTheme() === Themes.POLITIE ? `url(${backgroundImagePolitie})` :  `url(${backgroundImageDealerLease})` }}
                                            >
                                                { isLoadingGlobal({isLoading: this.props.isLoading}) ? (<Div theme={{ palette: ThemePalette.BRAND_ACCENT }} style={{flex: 1, flexDirection: "column", display: "flex"}}><LoadingIndicator type={LoadingIndications.DEFAULT}
                                                >{loadingMessagesGlobal({isLoading: this.props.isLoading})}</LoadingIndicator></Div>) : (
                                                    <>
                                                        <FlexRow
                                                            isFixed
                                                            className="v-sidebar__userinfo"
                                                        >
                                                            <NavbarStackContent>
                                                                {
                                                                    (!this.props.sidebarCollapsed || !value.breakpointAtSM) ? (
                                                                        <>
                                                                            <KeycloakLoggedIn>
                                                                                {!this.props.remoteErrors?.get(RemoteScope.INIT) && this.props.init?.email ? (
                                                                                    <UserInfoComp
                                                                                        name={this.props.init?.email}
                                                                                        role={this.props.getCurrentRole?.name ? this.props.getCurrentRole?.name : getText("app.no-role-active", "No role active")}
                                                                                        company={this.props.getCurrentCustomer?.name ? this.props.getCurrentCustomer?.name : undefined}
                                                                                    />) : this.props.remoteErrors?.get(RemoteScope.INIT) ? (
                                                                                    <UserInfoComp
                                                                                        name={getText("app.no-role-active", "No role active") ?? ""}
                                                                                    />
                                                                                )
                                                                                    : null
                                                                                }
                                                                            </KeycloakLoggedIn>
                                                                            <KeycloakNotLoggedIn>
                                                                                <UserInfoComp
                                                                                    name={"Not loggedin"}
                                                                                />
                                                                            </KeycloakNotLoggedIn>
                                                                        </>)
                                                                        : <></>}
                                                            </NavbarStackContent>
                                                            <NavbarStackRight isCollapsed={this.props.sidebarCollapsed && value.breakpointAtSM}>
                                                                {
                                                                    <>
                                                                        <>
                                                                            {(this.props.sidebarCollapsed && value.breakpointAtSM) &&
                                                                        <NavbarIcon>
                                                                            <Button
                                                                                className={"scl-o-navbar__icon"}
                                                                                link={{ href: "/" }}
                                                                                icon="home" />
                                                                        </NavbarIcon>
                                                                            }
                                                                            <>
                                                                                <KeycloakLoggedIn>
                                                                                    {(this.props.hasMultipleRoles() || this.props.hasCustomers()) &&
                                                                                <NavbarIcon>
                                                                                    <Button
                                                                                        link={{ onClick: this.props.triggerRoleModal }}
                                                                                        className={"scl-o-navbar__icon"}
                                                                                        icon="cog"
                                                                                    />
                                                                                </NavbarIcon>
                                                                                    }
                                                                                    {value.breakpointAtSM &&
                                                                                <NavbarIcon>
                                                                                    <LogoutBtn />
                                                                                </NavbarIcon>
                                                                                    }
                                                                                </KeycloakLoggedIn>
                                                                            </>
                                                                        </>
                                                                        {!value.breakpointAtSM &&
                                                                <NavbarIcon>
                                                                    <Button
                                                                        className={"scl-o-navbar__icon"}
                                                                        link={{
                                                                            onClick: this.setFocus(),
                                                                        }}
                                                                        icon={IconNames.TIMES}
                                                                    />
                                                                </NavbarIcon>
                                                                        }
                                                                    </>
                                                                }
                                                            </NavbarStackRight>
                                                        </FlexRow>
                                                        <FlexRow isScrollable className="v-sidebar__menu">
                                                            <FlexColumn className="v-sidebar__menu-content">
                                                                {(this.props.getCurrentRole && !isLoadingGlobal({isLoading: this.props.isLoading}) && (!this.props.sidebarCollapsed || !value.breakpointAtSM)) &&
                                                            <DashboardMenu
                                                                tabs={this.props.availablePages}
                                                            />
                                                                }
                                                                <FlexRow className={(this.props.sidebarCollapsed && value.breakpointAtSM) ? "v-sidebar__version-collapsed" : "v-sidebar__version"} isCollapsed={this.props.sidebarCollapsed || !value.breakpointAtSM}>
                                                                    {(!this.props.sidebarCollapsed || !value.breakpointAtSM) &&
                                                            <>
                                                                <div className="v-sidebar__title">
                                                                    {I18n.getText("app.app-title", "Fleetinformatie")}
                                                                </div>

                                                                <Logo img={getTheme() === Themes.POLITIE ? logoPolitie : this.getSidebarLogo(this.props.getCurrentRole?.label)} theme={getTheme()} variant={getTheme() === Themes.DUTCHLEASE ? ThemeVariants.BRAND : ThemeVariants.CONTRAST} className="v-sidebar__logo--vwpfs" />
                                                            </>
                                                                    }

                                                                </FlexRow>
                                                            </FlexColumn>
                                                        </FlexRow>
                                                        <FlexRow isFixed className="v-sidebar__apiinfo">
                                                            <ReleaseVersion
                                                                isCollapsed={this.props.sidebarCollapsed && value.breakpointAtSM}
                                                                apiVersion={this.props.init?.dateOfUpdate ?? ""}
                                                            />
                                                        </FlexRow>
                                                    </>
                                                )}
                                            </FlexColumn>
                                        </FlexRow>
                                        {(value.breakpointAtSM) && (
                                            <Button
                                                link={{ onClick: this.updateCollapseStateWithIcon(!this.props.sidebarCollapsed) }}
                                                theme={{
                                                    palette: ThemePalette.BRAND_ACCENT,
                                                    shadow: ThemeShadowSizes.TINY,
                                                }}
                                                className="m-info-card__icon"
                                                icon={this.props.sidebarCollapsed ? "chevron-right" : "chevron-left"}
                                                style={{ marginRight: "-35px", top: "50%" }}
                                            />
                                        )}
                                    </>
                                )}
                            </BreakpointConsumer>
                        </FlexSidebar>
                    </SwipeBlock>
                )}
            </BaseConsumer>
        );
    }

    private updateCollapseStateWithIcon(collapse: boolean) {
        return () => this.updateCollapseState(collapse);
    }

    private updateCollapseState(collapse: boolean) {
        this.props.toggleSidebar(collapse);
    }

    private setFocus() {
        return () => this.props.setFocus();
    }

    private getSidebarImage(label?: Label | string) {
        switch(label) {
        case Label.CUP:
            return backgroundImageCupra;
        case Label.AUL:
            return backgroundImageAudi;
        case Label.LUN:
            return backgroundImageXLLease;
        case Label.POR:
            return backgroundImagePorsche;
        case Label.SEL:
            return backgroundImageSeat;
        case Label.SKL:
            return backgroundImageSkoda;
        case Label.VWL:
            return backgroundImageVW;
        case Label.CAR:
            return backgroundImageVWB;
        case Label.MAN:
            return backgroundImageMAN;
        case Label.DLN:
            return backgroundImageDutchLease;
        }
        return backgroundImageDealerLease;
    }

    private getSidebarLogo(label?: Label | string) {
        switch(label) {
        case Label._700:
            return logoImageApoint;
        case Label._725:
            return logoImageCenturyLease;
        case Label.HKA:
            return logoImageHKAutolease;
        case Label._790:
            return logoImagePondealer;
        case Label.TBL:
            return logoImageTBLease;
        case Label._710:
            return logoImageUdenhout;
        case Label._711:
            return logoImageWealer;
        case Label._740:
            return logoImageWittebrug;
        case Label._730:
            return logoImageAutoHoogenboom;
        case Label.MUN:
            return logoImageMuntstad;
        case Label.MAA:
            return logoImageMaas;
        case Label.CUP:
            return logoImageCupra;
        case Label.VAL:
            return logoImageValleiLease;
        case Label.AUL:
            return logoImageAudi;
        case Label.LUN:
            return logoImageXLLease;
        case Label.POR:
            return logoImagePorsche;
        case Label.SEL:
            return logoImageSeat;
        case Label.SKL:
            return logoImageSkoda;
        case Label.VWL:
            return logoImageVW;
        case Label.CAR:
            return logoImageVW;
        case Label.MAN:
            return logoImageMan;
        case Label.DLN:
            return logoImageDutchLease;
        case Label.ALM:
            return logoImageAlmn;
        case Label.ZUI:
            return logoImageZuidLease;
        }
        return logoImageVWPFS;
    }
}

const SideBarWrapper: React.FunctionComponent = props => (<SideBarComp {...props as Props} />);

/**
 *
 */
export const SideBar = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SideBarWrapper);
